const slides = [
  {
    caption: "The Hopetoun | Canterbury",
    title: "A collection of 11 premium residences in the golden mile.",
    src: "hopetoun.jpg",
  },
  {
    caption: "The Wentworth | Canterbury",
    title: "Luxury living in the heart of Canterbury. Apartments & townhomes.",
    src: "theWentworth.jpg",
  },
  {
    caption: "The Gratis | Deepdene",
    title: "An unlimited you.",
    src: "gratis.jpg",
  },
  {
    caption: "Melfort | Hawthorn Heritage",
    title: "Elegant Heritage Meets Ingenious Contemporaneity.",
    src: "melfort.jpg",
  },
  {
    caption: "Daracome | Kew",
    title: "A collection of 18 truly exemplary residences.",
    src: "daracome.jpg",
  },
  {
    caption: "Wentworth Residence | Canterbury",
    title: "Seamlessly marries timeless elegance with contemporary comfort.",
    src: "wentworth.jpg",
  },
];
export default slides;
