<template>
  <div
    id="nav"
    style="position: absolute; color: white; opacity: 0.8; right: 2%"
  >
    <!--    <router-link to="/">Home</router-link> |-->
    <!--    <router-link to="/about">About</router-link>-->
  </div>
  <router-view />
</template>

<style lang="scss">
@import "main";

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
