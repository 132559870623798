<template>
  <div
    class="hive---fluid2"
    style="display: grid"
    :style="{ columnGap: colGap, rowGap: rowGap }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "Fluid2",
  props: {
    colGap: [String, Number],
    rowGap: [String, Number],
  },
};
</script>

<style scoped>
.hive---fluid2 {
  grid-template-columns: 1fr 1fr;
  justify-content: stretch;
  align-content: center;
  grid-auto-rows: min-content;
}
@media only screen and (max-width: 800px) {
  .hive---fluid2 {
    grid-template-columns: 1fr;
  }
  .hive---fluid2:last-child {
  }
}
</style>
