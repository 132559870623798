<template>
  <div class="home" style="width: 100%">
    <!--    ========================= cover ==========================-->
    <cover />
    <!--    ======================== intro ===============================-->
    <fluid2
      class="full-size gray-bg"
      col-gap="3%"
      row-gap="3%"
      style="padding: 5%; background-color: #dddddd"
    >
      <div class="">
        <div class="poppins big-font mb-2 full-width">
          Strength In Development & Construction
        </div>
        <div class="poppins">
          Welcome to Luban Build. Development Advisory and Project Management.
          <span class="hide-sm"
            >It’s simple. We deliver high-quality projects that consistently
            exceed expectation. We work with leading architects, developers and
            consultants who share our passion for design, detail and
            craftsmanship – the results are there to see in every project we
            deliver. We bring strength to your project, like it’s our own. We’re
            in it together.</span
          >
        </div>
      </div>
      <div>
        <div
          style="
            height: 50vh;
            width: 100%;
            background-image: url('/photos/gratis-living.jpg');
          "
          class="bg-img-contain"
        ></div>
        <!--        <img class="" src="@/assets/gratis-living.jpg" />-->
      </div>
    </fluid2>
    <!--    ========================== fullscreen image =====================-->
    <div
      class="full-size cover relative responsive-font"
      style="background-image: url('/photos/benchtop.jpg'); color: white"
    >
      <div
        class="absolute poppins bold"
        style="top: 5%; left: 5%; width: 50%; font-size: 3vw; opacity: 0.5"
      >
        Crafting Excellence, <br />Strengthening in Every Detail.
      </div>
    </div>
    <!--    ================================ phil ================================-->
    <!--    <div class="full-size gray-bg flex-center">-->
    <!--      <div class="phil full-size" style="padding: 15%">-->
    <!--        <div style="width: 40%; margin-left: 60%; min-width: 130px">-->
    <!--          <div class="caslon full-width" style="font-size: 2em">Phil Li</div>-->
    <!--          <div class="poppins full-width">-->
    <!--            FOUNDER / DIRECTOR<br />B.F.A. - CAFA(CHINA)-->
    <!--          </div>-->
    <!--          <div class="poppins hide-sm" style="font-size: 0.8em">-->
    <!--            Broad experience across construction and development with tier one-->
    <!--            designers, builders & developers for over 20 years of experience-->
    <!--            both local & international.-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    ======================== intro2 ===============================-->
    <fluid2
      class="full-size gray-bg"
      col-gap="3%"
      row-gap="3%"
      style="padding: 5%; background-color: #dddddd"
    >
      <div>
        <div
          style="
            height: 50vh;
            width: 100%;
            background-image: url('/photos/wentworth-living.jpg');
          "
          class="bg-img-contain"
        ></div>
        <!--        <img class="" src="@/assets/gratis-living.jpg" />-->
      </div>
      <div class="">
        <div class="poppins big-font mb-2 full-width">
          Defined by work ethic, not by specialism
        </div>
        <div class="poppins">
          We make a conscious choice to bring the same level of quality,
          knowledge, understanding and enthusiasm to each project, be it
          residential or commercial.
          <span class="hide-sm">
            Our team possesses a deep understanding of the nuances of each
            sector and we’re ready to give our all for each new challenge.
          </span>
        </div>
      </div>
    </fluid2>
    <div
      class="full-size cover relative responsive-font"
      style="
        background-image: url('/photos/fewster-fireplace.jpg');
        color: white;
      "
    >
      <div
        class="absolute poppins bold"
        style="
          top: 5%;
          right: 5%;
          width: 50%;
          font-size: 3vw;
          opacity: 0.5;
          text-align: right;
        "
      >
        Timeless Homes, <br />Modern Living.
      </div>
    </div>
    <!--    ================================ frank ================================-->
    <!--    <div class="full-size gray-bg flex-center">-->
    <!--      <div class="frank full-size" style="padding: 15%">-->
    <!--        <div style="width: 40%; margin-right: 60%; min-width: 130px">-->
    <!--          <div class="caslon full-width" style="font-size: 2em">Frank Lu</div>-->
    <!--          <div class="poppins full-width mb-2">-->
    <!--            MANAGER<br />-->
    <!--            DBU-->
    <!--            <span class="hide-sm"-->
    <!--              ><br />Bacholor of Property and Construction - Melbourne-->
    <!--              University <br />Bachelor of Commerce - Melbourne University</span-->
    <!--            >-->
    <!--          </div>-->
    <!--          <div class="poppins hide-sm" style="font-size: 0.8em">-->
    <!--            Frank is an accomplished project manager with 15 years of experience-->
    <!--            and the delivery of over 1,000 apartments to his name. His project-->
    <!--            management skills are bolstered by significant contract-->
    <!--            administration experience, as well as a detailed understanding of-->
    <!--            town planning, development management and final stage delivery-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <projects />
    <!--    ====================== people ==============================-->
    <fluid2
      class="full-size gray-bg poppins"
      col-gap="3%"
      row-gap="3%"
      style="padding: 5%; background-color: #dddddd; text-align: center"
    >
      <div>
        <div
          style="
            height: 35vh;
            width: 100%;
            background-image: url('/photos/phil.jpg');
          "
          class="bg-img-contain"
        ></div>
        <div class="bold">PHIL LI</div>
        <div>DIRECTOR / FOUNDER</div>

        <!--        <img class="" src="@/assets/gratis-living.jpg" />-->
      </div>
      <div>
        <div
          style="
            height: 35vh;
            width: 100%;
            background-image: url('/photos/yana.jpg');
          "
          class="bg-img-contain"
        ></div>
        <div class="bold">YANA CHEN</div>
        <div>MANAGER / CO-FOUNDER</div>

        <!--        <img class="" src="@/assets/gratis-living.jpg" />-->
      </div>
    </fluid2>
    <!--    ================================ footer ================================-->
    <div class="full-size bg-gray-300 flex flex-col">
      <div class="flex-grow flex-center" style="padding: 5%">
        <div class="poppins big-font mb-4 text-center">
          We add muscle to your project
        </div>
        <div class="poppins text-center" style="max-width: 1000px">
          At Luban Build we offer Development Advisory and Project Management
          services.<br /><br />
          We’re strong, bringing development muscle and confidence to your
          project. With our mix of brains and brawn, our business is set up to
          support yours like no other in this space.
          <span class="hide-sm"
            ><br /><br />Our hand-picked collaborators share our unique spirit –
            together we get the job done and done right.</span
          >
        </div>
      </div>
      <div
        class="bg-gray-500 text-white flex-center text-center cover"
        style="height: 25vh"
      >
        <div class="font-bolder mb-2 uppercase">
          Luban Build Pty Ltd
          <div class="text-sm">ABN 39 603 667 474</div>
        </div>
        <div class="text-sm opacity-80">
          <div>+61 3 6161 3122</div>
          <a href="mailto:hello@luban.build">hello@luban.build</a><br />
          91 Canterbury Road<br />Canterbury VIC 3126
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Fluid2 from "@/components/Fluid2";
import Cover from "@/views/Cover";
import Projects from "@/views/Projects";

export default {
  name: "Home",
  components: {
    Projects,
    Cover,
    Fluid2,
  },
};
</script>
<style>
.phil {
  background-image: url("~@/assets/phil-croped.jpg");
  background-size: cover;
}
.frank {
  background-image: url("~@/assets/frank.jpg");
  background-size: cover;
  background-position: right;
}
/*.fluid2 {*/
/*  display: inline-block;*/
/*  width: 49%;*/
/*  padding: 3%;*/
/*}*/
@media only screen and (max-width: 800px) {
  .fluid2 {
    width: 100%;
  }
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
