<template>
  <div class="full-size gray-bg flex-center pl-5 pr-5">
    <div class="poppins big-font mb-4">Our Projects</div>
    <div class="full-width" style="height: 60vh">
      <hive-photo-slider-basic
        :base-url="baseURL"
        :slides="projectSlider"
        auto-play
        style="height: 60vh"
        @slide="onSlide"
      >
      </hive-photo-slider-basic>
    </div>
    <hive-text-carousel
      :content="carouselText"
      :current-label="currentSlide"
      class="w-full px-2 text-center mt-4"
    />
  </div>
</template>

<script>
import projectSlider from "@/views/projectSlider";
import HivePhotoSliderBasic from "@/pepper/components/HivePhotoSliderBasic";
import { ref } from "vue";
import HiveTextCarousel from "@/pepper/components/HiveTextCarousel";
export default {
  name: "Projects",
  components: { HiveTextCarousel, HivePhotoSliderBasic },
  setup() {
    const carouselText = [];
    for (let i = 0; i < projectSlider.length; i++) {
      const slide = projectSlider[i];
      const txt = `<div class="title">${slide.caption}</div><div class="sub-title">${slide.title}</div>`;
      carouselText.push(txt);
    }
    const currentSlide = ref(0);
    function onSlide(index) {
      currentSlide.value = index;
    }
    return {
      baseURL: "/photos/projects/",
      carouselText,
      onSlide,
      currentSlide,
      projectSlider,
    };
  },
};
</script>

<style scoped></style>
