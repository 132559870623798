<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 403 227"
    xml:space="preserve"
    :style="{
      fill: color,
    }"
  >
    <g>
      <path
        d="M36,63.4c0,2.2,0,30.8,0.4,36.1H44c6.2,0,12.2,0,19.3-0.7c7.1-0.7,16.1-16.5,18.1-21.4c0.4-0.8,0.8-1.2,1.6-0.8l2.6,1.1
		c0.4,0.3,0.4,0.4,0.3,1.2c-0.4,0.9-5.7,22.7-6.5,26.3c-3.4-0.3-41.5-0.4-53.7-0.4c-13.1,0-17.9,0.4-19.6,0.4
		c-0.9,0-1.1-0.3-1.1-1.2v-3.3c0-0.7,0.1-0.9,0.9-0.9h4.1c4.9,0,5.7-1.9,5.7-3.3c0-2.5,0.4-35.4,0.4-43.5v-7.4
		c0-8.5-0.3-22.5-0.4-24.6c-0.3-3.4-0.9-4.8-4-4.8H6.9c-0.8,0-0.9-0.7-0.9-1.3v-3.2c0-0.7,0.1-0.9,0.9-0.9c1.5,0,6.2,0.4,18.2,0.4
		c15.5,0,21-0.4,22.2-0.4c1.1,0,1.2,0.4,1.2,1.3v3c0,0.7-0.3,1.1-1.3,1.1H41c-4.1,0-4.6,1.3-4.6,4.9c0,1.7-0.4,21.7-0.4,30.9V63.4z"
      />
      <path
        d="M141.1,106.1c-0.8,0.3-1.7,0.5-2.9,0.5c-1.3,0-2.1-0.7-2.2-1.6l-0.8-7.1h-0.8c-2.6,4.6-10.3,8.7-17.5,8.7
		c-5.3,0-9.3,0-13.9-6.1c-5.6-7.4-5-11.6-5-23l0.1-5.8c0-5,0-10.3-0.1-13.2c-0.1-3.2-2.9-4.4-4.5-4.6c-2.2-0.4-2.9-0.3-2.9-0.9v-2.6
		c0-0.8,0.3-1.1,1.5-1.1h19.3c0.8,0,1.3-0.1,1.9-0.3c0.5,0,1.1-0.1,1.5-0.1c0.9,0,1.6,0.7,1.5,1.6c-0.1,1.2-0.5,7.4-0.5,17.9v7.1
		c0,5.3-0.8,15.2,2.5,20.4c2,3,4.1,2.8,6.5,2.8c2.2,0,5.2-1.7,7.1-4.2c1.2-1.3,2.1-3.4,2.1-4.8l-0.1-31.1c0-3.3-2.4-4.4-5.7-4.6
		c-1.6-0.1-2.2-0.3-2.2-0.9v-2.5c0-1.1,0.3-1.2,1.6-1.2h19.7c0.9,0,1.6-0.1,2.2-0.3c0.7,0,1.2-0.1,1.6-0.1c0.8,0,1.3,0.7,1.2,1.3
		c-0.1,1.2-0.8,4.6-0.8,18v9.8c0,6.3,0,12.8,0.3,15.2c0.4,4.1,1.9,4.6,2.4,4.6c0.8,0,2.8,0,4.4-0.4c1.1-0.3,1.5,0,1.6,0.5l0.4,2.5
		c0.1,0.7-0.1,0.9-0.7,1.1L141.1,106.1z"
      />
      <path
        d="M173.1,106.5c-0.7,0.4-1.1,0.4-1.6,0.3l-1.9-0.7c-0.5-0.1-0.7-0.4-0.5-1.1c0.4-1.9,0.4-12.8,0.4-22.7V32.9
		c0-8.9-1.7-10.4-5.8-11.6l-1.9-0.5c-1.5-0.4-1.7-0.7-1.7-1.1v-1.6c0-0.4,0.3-0.9,1.5-1.3c6.6-1.9,14.9-5.2,20.1-7.5
		c2.2-1.2,3.3-2,4.6-2s1.6,0.9,1.6,2.2c0,1.1-0.9,16.1-1.1,29.1l-0.3,15.1c4.8-4.8,11.1-6.3,17.7-6.3c8.6,0,15.3,5.7,18.8,11.2
		c2.2,3.6,3.8,8.5,3.8,17.1c0,13.9-13.2,30.9-30.3,30.9c-8.7,0-13.8-1.6-18.1-3.4L173.1,106.5z M186.8,76.6c0,11-0.1,15.9,1.6,19.3
		c1.9,3.4,4.1,5.7,7.7,5.7c3.8,0,8.5-1.3,11-6.9c2.8-6.1,2.1-9.4,2.1-14.7c0-17.5-7.3-23.3-14.1-23.3c-2.4,0-5.6,0.8-8.2,2.5V76.6z"
      />
      <path
        d="M264.5,62.6c0-2.6-0.3-5.7-2-7.3c-1.3-1.2-3.4-1.6-5.6-1.6c-2.4,0-4.6,0.5-7,2.1c-2,1.3-0.4,3.6-0.4,7.8
		c0,4.5-3.3,7.5-8.6,7.5c-3,0-5.7-1.9-5.7-4.8s0.9-6.9,3.7-9.3c5.6-5,14.7-9.8,25.4-9.8c8.5,0,10.7,1.3,13.5,3.8
		c2.9,2.6,3.7,6.5,3.7,11.2l0.3,26.3c0.1,6.9,0.5,9,3,9c1.9,0,3.3-1.5,3.8-2.4c0.5-0.8,0.9-0.9,1.3-0.7l1.9,1.1
		c0.5,0.3,0.9,0.7,0.4,1.9c-1.2,2.8-5.2,9-13.4,9c-5.2,0-10-1.9-12.6-8.1h-0.7c-3.3,3-6.1,4.9-11.4,6.9c-2.6,0.9-5.7,1.2-9.5,1.2
		c-4.6,0-8.7-5-8.7-12.2c0-5.8,2.8-11.9,11.4-16.1c10.3-4.9,13.6-6.3,17.1-8.7V62.6z M264.6,74.9c-6.5,3.7-11.9,5.7-11.9,14.8
		c0,6.1,2.8,8.6,5.7,8.7c3.6-0.3,6.2-3.6,6.2-4.8V74.9z"
      />
      <path
        d="M319,54.6h1.1c1.5-1.7,3.8-3.4,6.3-4.8c2.9-1.6,5.8-2.5,10.2-2.5c5.2,0,10.2,0.5,13.6,3.7c5.6,5.2,6,8.5,6,15.1v14
		c0,4.5-0.3,10.7-0.5,15.3c-0.1,4,2,4.6,3.2,4.6h3.6c1.2,0,1.5,0.4,1.5,1.2v2.4c0,0.9-0.3,1.5-1.1,1.5c-0.9,0-5-0.4-15.6-0.4
		c-8.7,0-13,0.4-13.9,0.4c-0.8,0-1.1-0.4-1.1-1.5v-2.4c0-0.9,0.4-1.2,1.7-1.2h1.5c0.9,0,2.1-0.7,2.4-3c0.5-3.4,0.8-7.7,0.8-13.8
		V69.2c0-5-0.4-8.5-3.2-11c-2.4-2.1-4.2-2.4-6.5-2.4c-2.4,0-4.1,0.7-6.7,2.6c-1.6,1.2-2.6,4.8-2.6,10.2l-0.3,27.1
		c0,2.4,0.9,4.4,2.1,4.4h1.7c1.1,0,1.3,0.4,1.3,1.3v2.5c0,0.8-0.3,1.2-0.9,1.2c-0.9,0-4.5-0.4-13.2-0.4c-9.4,0-13.6,0.4-14.4,0.4
		c-0.7,0-0.9-0.4-0.9-1.2v-2.6c0-0.8,0.3-1.2,1.3-1.2h2c1.5,0,3.7-0.4,3.7-2.6V68.1c0-4-2.2-6.2-5.3-7.7l-1.2-0.5
		c-0.8-0.4-0.9-0.9-0.9-1.5v-1.2c0-0.7,0.5-1.1,1.2-1.3l16.9-7.7c1.1-0.4,2-0.9,3-0.9c0.8,0,1.6,0.7,1.9,1.6L319,54.6z"
      />
      <path
        d="M374,96.2c0-4,3.2-10,10.7-10c5.6,0,10.2,4.2,10.2,10.6c0,6-4.4,9.9-10,9.9C378.6,106.6,374,102.2,374,96.2z"
      />
    </g>
    <g>
      <path
        d="M104.1,159.8c0-4.9-0.3-21-0.4-23.1c-0.4-7.7-2.5-7.9-6.9-7.9h-4.2c-0.9,0-0.9-0.1-0.9-1.2v-3c0-0.8,0.1-1.2,1.1-1.2
		c1.7,0,8.1,0.4,20.6,0.4c4.2,0,12.2-0.4,21.2-0.4c9.5,0,18,0.9,22.6,3.6c7.5,4.1,10.2,10.2,10.2,18c0,8.7-5.3,17.2-19.3,21.8v0.3
		c16.4,4.2,25,12.2,25,26.2c0,7.1-4,16.9-10.3,20.1c-5.4,2.8-14.7,4.9-31.1,4.9c-1.2,0-4.6-0.3-8.3-0.4c-3.8-0.3-7.9-0.4-10.4-0.4
		c-6.6,0-11,0.1-13.9,0.1c-2.8,0.1-4.2,0.3-5.2,0.3c-1.3,0-1.5-0.4-1.5-1.5v-2.9c0-0.8,0.1-1.1,1.3-1.1h4.6c4,0,4.6-1.6,5.2-5.6
		c0.4-3,0.7-20.5,0.7-31.3V159.8z M123.4,165.2c5.4,0.5,13.9-0.3,17.7-3.7c4.4-3.8,6.3-9.8,6.3-15.6c0-5-1.6-10.3-5.4-13.4
		c-4.5-3.6-11-4.8-12.4-4.8c-2.8,0-5.7,0.1-5.8,2.2c-0.3,2.6-0.4,14.1-0.4,25.1V165.2z M123.4,195.9c0,12.6,2.9,17.7,9.9,17.7
		c9.4,0,18.2-4.6,18.2-21.2c0-10.7-4.8-17.7-11.9-20.5c-6.6-2.5-13-2.2-16.3-2.2V195.9z"
      />
      <path
        d="M230.8,218.6c-0.8,0.3-1.7,0.5-2.9,0.5c-1.3,0-2.1-0.7-2.2-1.6l-0.8-7.1h-0.8c-2.6,4.6-10.3,8.7-17.5,8.7
		c-5.3,0-9.3,0-13.9-6.1c-5.6-7.4-5-11.6-5-23l0.1-5.8c0-5,0-10.3-0.1-13.2c-0.1-3.2-2.9-4.4-4.5-4.6c-2.2-0.4-2.9-0.3-2.9-0.9v-2.6
		c0-0.8,0.3-1.1,1.5-1.1H201c0.8,0,1.3-0.1,1.9-0.3c0.5,0,1.1-0.1,1.5-0.1c0.9,0,1.6,0.7,1.5,1.6c-0.1,1.2-0.5,7.4-0.5,17.9v7.1
		c0,5.3-0.8,15.2,2.5,20.4c2,3,4.1,2.8,6.5,2.8c2.2,0,5.2-1.7,7.1-4.2c1.2-1.3,2.1-3.4,2.1-4.8l-0.1-31.1c0-3.3-2.4-4.4-5.7-4.6
		c-1.6-0.1-2.2-0.3-2.2-0.9v-2.5c0-1.1,0.3-1.2,1.6-1.2h19.7c0.9,0,1.6-0.1,2.2-0.3c0.7,0,1.2-0.1,1.6-0.1c0.8,0,1.3,0.7,1.2,1.3
		c-0.1,1.2-0.8,4.6-0.8,18v9.8c0,6.3,0,12.8,0.3,15.2c0.4,4.1,1.9,4.6,2.4,4.6c0.8,0,2.8,0,4.4-0.4c1.1-0.3,1.5,0,1.6,0.5l0.4,2.5
		c0.1,0.7-0.1,0.9-0.7,1.1L230.8,218.6z"
      />
      <path
        d="M286.9,217.7c-1.1,0-7.1-0.4-18.6-0.4c-7.7,0-12.8,0.4-14.3,0.4c-0.7,0-1.1-0.3-1.1-1.3v-2.6c0-0.8,0.4-1.1,1.3-1.1h4.9
		c1.5,0,2.4-0.5,2.5-2.6c0.3-4,0.3-8.1,0.3-11.8l-0.3-18.5c0-3.7-0.8-6.3-6.3-8.3c-1.5-0.5-1.6-1.1-1.6-1.9v-1.5
		c0-0.4,0.3-0.7,1.5-1.1l19.7-6.3c1.5-0.5,2.6-0.8,3.6-0.8c1.1,0,1.5,0.7,1.3,1.6c-0.3,2.2-0.3,10.6-0.3,22.2v14.1
		c0,5.2,0,9.9,0.1,11.4c0.3,2.5,0.9,3.4,2.4,3.4h4.8c1.1,0,1.3,0.4,1.3,1.2v2.5C288.1,217.3,287.8,217.7,286.9,217.7z M268.8,149.8
		c-5.6,0.1-9.7-4.2-9.7-9.5c0-4.4,4.5-8.7,10.3-8.7c6.5,0,9.8,4.5,9.8,8.3C279.2,144.3,276.2,149.6,268.8,149.8z"
      />
      <path
        d="M317.3,190.8c0,5.3,0,13.9,0.4,17.7c0.1,2.2,0.5,4.1,3.3,4.1h4.5c1.2,0,1.5,0.4,1.5,1.2v2.5c0,0.8-0.3,1.3-0.8,1.3
		c-0.9,0-6.7-0.4-17.2-0.4c-11.1,0-16.4,0.4-17.6,0.4c-0.7,0-0.9-0.5-0.9-1.6v-2.2c0-0.9,0.3-1.2,1.7-1.2h4c1.5,0,3.6-0.9,3.6-5.7
		v-42c0-9.4,0-20.1-0.3-23.5c-0.3-4.5-3-6.3-7.3-7.7l-2.4-0.8c-0.9-0.4-1.1-0.8-1.1-1.5v-2c0-0.4,0.3-0.9,1.2-1.1
		c6-1.2,19.7-5.7,23.5-7.7c1.5-0.7,2.2-0.9,2.9-0.9c0.8,0,1.3,0.7,1.2,2.4c-0.1,7.3-0.3,34.8-0.3,43.9V190.8z"
      />
      <path
        d="M370.8,134.9c-0.1-3.7-1.6-5.8-8.6-7.5c-0.8-0.1-0.9-0.4-0.9-0.9v-2.5c0-0.5,0.3-0.9,1.1-1.1c7.8-0.7,18-1.5,22.3-2.4
		c1.9-0.4,2.4-0.7,3.4-0.7c0.8,0,1.3,0.9,1.2,2.4c-0.5,6.6-0.8,25.8-0.8,44.4v29.2c0,4.9,0,10,1.3,12.2c1.1,1.7,2.8,1.9,6,1.5
		c1.5-0.1,2,0.3,2.1,0.9l0.4,2c0.1,0.8-0.1,1.2-0.9,1.5l-19.8,5c-0.8,0.3-1.5-0.1-1.9-1.2l-2.1-6.6c-4.2,4.9-12,8.1-17.7,8.1
		c-6.9,0-9.5-0.5-15.7-6.3c-5.2-4.6-8.3-10-8.3-18.8c0-10.6,1.9-17.5,9.3-25.7c5.2-5.7,11.2-8.5,19.6-8.5c4.4,0,8.2,0.8,10.7,1.7
		L370.8,134.9z M371.2,177.3c0-2.4-0.1-4.6-1.1-6.3c-2-3.6-6.3-6-10-6c-2.4,0-4.9,0.9-6.9,3.7c-3.2,4.4-4.2,8.9-4.2,15.2
		c0,15.6,6.7,26.4,14.9,26.4c3.6,0,5-1.3,6.3-2.6c1.1-1.1,1.3-3.4,1.3-6.3L371.2,177.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style scoped></style>
