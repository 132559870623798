<template>
  <div
    class="cover full-size"
    style="background-image: url('/photos/wentworth-staircase.jpg')"
  >
    <div class="absolute inset-0" ref="container">
      <!--      <div class="fixed text-white" style="top: 0">-->
      <!--        <div>{{ logoWidth }}</div>-->
      <!--        <div>y:{{ y }}/{{ vh }}</div>-->
      <!--      </div>-->

      <logo
        style="
          position: absolute;
          max-width: 70%;
          left: 10%;
          bottom: 10%;
          opacity: 0.6;
        "
        :style="{ width: logoWidth + 'px' }"
        color="white"
      />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import { ref, watch } from "vue";
import { useWindowScroll } from "@vueuse/core";
export default {
  name: "Cover",
  components: { Logo },
  setup() {
    // const container = templateRef("container");
    // const { top, bottom } = useElementBounding(container);
    const { y } = useWindowScroll();

    const vh = window.innerHeight;
    const logoWidth = ref(600);
    watch(y, (v) => {
      let w = ((vh - v) / vh) * 500 + 100;
      if (w < 200) w = 200;
      logoWidth.value = w;
    });
    return {
      logoWidth,
    };
  },
};
</script>

<style scoped></style>
